
function App() {
  return (
    <div className="App">
      <p className="text-4xl font-bold">Hello Abhishek...!!</p>
    </div>
  );
}

export default App;
